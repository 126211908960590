.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 400px;
  padding: 24px;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      .text-field {
        min-width: 120px;
        margin-bottom: 16px;
      }

      .horizontal {
        flex-direction: row;
        justify-content: space-between;
        .text-field:not(:first-child) {
          margin-left: 12px;
        }
        .text-field:last-child {
          width: 100%;
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
      .form-button {
        margin-left: 16px;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .form-container {
    width: auto;
    form {
      .buttons-container {
        flex-direction: column-reverse;
        .form-button {
          margin-left: 0px;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.search-field-container {
    padding-bottom: 8px;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    border-radius: 6px;
    background-color: rgba(165, 163, 163, 0.192);
    &:hover {
        background-color: rgba(165, 163, 163, 0.432);
    }
}

.prefix-icon {
    margin-right: 8px;
}

.suffix-content {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 16px;
}

.search-field-container {
    padding: "2px 4px";
    display: "flex";
    align-items: "center";
}
.input {
    margin-left: 16px;
    flex: 1;
}
.iconButton {
    padding: 10;
}

.root {
    display: flex;
}

.menu-button {
    margin-right: 16px;
}

.title {
    flex-grow: 1;
    cursor: pointer;
}

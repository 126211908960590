body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App, html, body, #root {
  height: 100%;
}

.list {
    .list-item-content {
        display: flex;
    }
    .list-item {
        display: flex;
    }
}

@media only screen and (max-width: 500px) {
    .list {
        .non-essential {
            display: none;
        }
        .list-item-content {
            justify-content: center;
            align-items: center;
            width: 33%;
        }
        .list-item {
            justify-content: space-evenly;
        }
    }
}
